<template>

    <div>
        <section class="selfcare-title d-flex">
            <div class="px-2">
                <a @click="goBack()" class="custom-link text-light">
                    <i class="bi bi-arrow-left"></i>
                </a>
            </div>
            <div class="text-light width-available text-center">

            </div>
        </section>
        <section class="p-2 terms">
            <div style="color: var(--white);" class="container ">
                <h6 class="pb-3 text-center">100% BONUS POLICY
                </h6>
                <p>This bonus is claimable once upon registration.</p>
                <p>To enjoy this bonus, you need to deposit a minimum amount of Kes.100. </p>
                <p>Under the Falcon Bet Bonus policy, you can only claim a maximum of Kes. 15000.
                </p>
                <p>After loading your wallet for the first time with a deposit, the amount that reflect on your account
                    balance is loaded with 100 % bonus.
                </p>
                <p>The account balance will be transferred to available balance upon wager.
                </p>
                <p>The bonus will be credited to the customer's account automatically after the deposit is made.
                </p>
                <p>Customers can only withdraw bonus after wagering it for 30 times. </p>
                <p>The bonus must be wagered within 8 days.
                </p>
                <p>If the 8 days expires without customer’s compliance on the redemption policy above, all bonuses and
                    winnings will be removed from account.
                </p>
                <p>The offer is not valid in conjunction with any other promotions or special offers.</p>
                <p>If the company in its own discretion believes /suspects a customer is misusing the bonus, or in
                    conduct of foul play and/or participation in strategies that the company in its sole discretion
                    deems to be abusive, the company reserves the right to cancel the bonus and all the accrued
                    benefits, without any explanation. </p>

                <p>The bonus may be canceled at any time without prior notice, but only before the bonus amount is
                    wagered
                    or withdrawn. In cases of dispute, the decision of the company is deemed to be final and binding.
                </p>
                <p>The bonus is deemed to have been wagered if the customer's remaining account balance is less than the
                    minimum stake, or if the wagering requirement has been met in full. </p>
                <p>The bonus will be awarded only after a deposit has been credited to the customer's account. If the
                    deposit is withdrawn before the bonus has been credited, the company reserves the right to withhold
                    the
                    bonus.
                </p>
                <p>Only one bonus is allowed per family, customer, address, per shared computer and shared IP address,
                    IMEI
                    Number and per any account details including phone number, M-Pesa account details and payment system
                    account number. Any misuse of this bonus offer will lead to the cancellation of the bonus and all
                    bonus
                    winnings, or to the closure of the account.
                </p>
                <p>The customer must provide ID documents, when necessary, to validate their identity (KYC). Failure to
                    produce these documents if requested will result in the bonus and any winnings being forfeited. The
                    Company reserves the right to request, at any time, that the customer provides photographic evidence
                    of
                    themselves holding their ID (the customer’s face must be clearly visible in the photo) or undergoes
                    identification via telephone. </p>
                <p>Falcon Bet has the right to freeze funds and close the customer's account if any fraudulent or money
                    laundering activities are detected.
                </p>
                <p>The offer is only available to customers with one user account.</p>
                <p>Savvy Bet Limited T/A Falcon Bet has the right to amend the terms of the offer, cancel or renew the
                    offer, or refuse to allow participation at any time without prior notice.</p>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "BonusTnC"
}
</script>
<style scoped>
body {
    font-family: Arial, sans-serif;
    padding: 20px;
}

h1,
h2 {
    margin-top: 30px;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.5em;
}

p {
    margin-bottom: 10px;
}

.definition-list {
    margin-top: 20px;
}

.definition-list dt {
    font-weight: bold;
}

.definition-list dd {
    margin-bottom: 10px;
}
</style>